import services from "../axios/dbManag";

export const editTecnico = (persona) => {
  return services.dbManag
    .post("/affiliazioni/societa/tecnici/edit", {
      ...persona,
      //   "anno": 0,
      //   id_societa: 0,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const addTecnico = (
  id_settore,
  id_persona,
  id_tipo_tecnico,
  dataInizio,
  dataFine,
  anno,
  id_societa
) => {
  return services.dbManag
    .post("/affiliazioni/societa/tecnici/add", {
      id_settore,
      id_persona,
      id_tipo_tecnico,
      dataInizio,
      dataFine,
      anno,
      id_societa,
    })
    .then((res) => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};

export const listTecnici = (id_societa, anno) => {
  return services.dbManag
    .post("/affiliazioni/societa/tecnici/list", {
      anno,
      id_societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
